<script>
  import { Router, Route } from "svelte-routing";
  import { setupI18n, isLocaleLoaded } from "./services/i18n";
  import Navigation from "./components/Navigation.svelte";
  import Footer from "./components/Footer.svelte";

  import Home from "./routes/Home.svelte";
  import Schedule from "./routes/Schedule.svelte";
  import Other from "./routes/Other.svelte";
  import Missing from "./routes/404.svelte";
  import RSVP from "./routes/RSVP.svelte";
  import Mail from "./routes/Mail.svelte";

  $: if (!$isLocaleLoaded) {
    setupI18n();
  }
</script>

<style>
  .app {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    box-sizing: border-box;
  }
</style>

<div class={`app`}>
  {#if $isLocaleLoaded}
    <Router>
      <Navigation />
      <main>
        <Route path="/other" component={Other} />
        <Route path="/schedule" component={Schedule} />
        <Route path="/rsvp/:success" component={RSVP} />
        <Route path="/rsvp" component={RSVP} />
        <Route path="/mail/:secret/:success" component={Mail} />
        <Route path="/mail/:secret" component={Mail} />
        <Route path="/" component={Home} />
        <Route component={Missing} />
      </main>
    </Router>
    <Footer />
  {/if}
</div>
