<script>
  import { getContext } from "svelte";
  import { links } from "svelte-routing";
  import { ROUTER } from "svelte-routing/src/contexts";
  import { _, locale, setLanguage } from "../services/i18n";

  let { activeRoute } = getContext(ROUTER);
  $: pathname = $activeRoute && $activeRoute.uri;
  $: homeClass = pathname === "/" ? "selected" : "";
  $: scheduleClass = pathname === "/schedule" ? "selected" : "";
  $: otherClass = pathname === "/other" ? "selected" : "";
  $: rsvpClass = pathname && pathname.startsWith("/rsvp") ? "selected" : "";
</script>

<style>
  ul {
    list-style-type: none;
    display: flex;
    min-height: 50px;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  nav {
    text-align: center;
    padding: 16px 0;
    min-width: 80%;
    margin: auto;
  }
  a,
  .language {
    display: flex;
    text-decoration: none;
    text-transform: capitalize;
    font-weight: 300;
    font-size: 2em;
  }
  .active {
    opacity: 0.3;
  }
  * {
    color: #25371a;
  }

  a:hover,
  span:hover:not(.active),
  .selected {
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
  }

  li {
    font-family: "Euphoria Script", serif;
    padding: 8px 16px;
  }

  @media (min-width: 460px) {
    ul {
      padding: 0 32px;
    }
  }
</style>

<nav use:links>
  <ul>
    <li>
      <a href="/" class={homeClass}>&nbsp;{$_('home')}&nbsp;</a>
    </li>
    <li>
      <a href="/schedule" replace class={scheduleClass}>
        &nbsp;{$_('schedule')}&nbsp;
      </a>
    </li>
    <li>
      <a href="/other" replace class={otherClass}>&nbsp;{$_('other')}&nbsp;</a>
    </li>
    <li>
      <a href="/rsvp" replace class={rsvpClass}>&nbsp;{$_('rsvp')}&nbsp;</a>
    </li>
    <li>
      <div class="language">
        <span
          class={$locale === 'en' ? 'active' : 'select'}
          on:click={e => setLanguage('en')}>
          &nbsp;En&nbsp;
        </span>
        <span class="active">/</span>
        <span
          class={$locale === 'sv' ? 'active' : 'select'}
          on:click={e => setLanguage('sv')}>
          &nbsp;Sv&nbsp;
        </span>
      </div>
    </li>
  </ul>
</nav>
