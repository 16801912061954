<style global lang="scss">
  @import "../styles/form.scss";
</style>
<script>
  import { navigate } from "svelte-routing";
  import { _ } from "../services/i18n";
  export let action;
  let errors = { name: false, address: false };
  const validate = e => {
    if (e.target.value) errors[e.target.name] = false;
  };
  const onSubmit = e => {
    e.preventDefault();
    const form = e.target;
    if (!form.name.value) errors.name = true;
    if (!form.address.value) errors.address = true;
    if (Object.values(errors).some(error => error)) return;
    fetch(form.action, { method: "post", body: new FormData(form) });
    navigate(action);
  };
</script>
<form name="newformmail" action="{action}" on:submit="{onSubmit}" netlify>
  <p>
    <label>
      <span>
        {$_('form.name')}
        <span class="error"
          >{errors.name ? ` (${$_('form.required')})` : ''}</span
        >
      </span>
      <input type="text" name="name" on:input="{validate}" />
      <span class="underline" />
    </label>
    <label>
      <span>
        {$_('form.address')}
        <span class="error"
          >{errors.address ? ` (${$_('form.required')})` : ''}</span
        >
      </span>
      <textarea
        type="text"
        name="address"
        rows="7"
        on:input="{validate}"
      ></textarea>
      <span class="underline" />
    </label>
    <input type="hidden" name="form-name" value="newformmail" />
    <button
      type="submit"
      disabled="{Object.values(errors).some(error => error)}"
    >
      {$_('form.send')}
    </button>
  </p>
</form>
