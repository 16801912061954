<script>
  import { _ } from "../services/i18n";

  export let key;
  export let errors;

  let validationErrors = { name: true };

  let menu = "With Alcohol";
  let value = "";

  let bus = false;

  let allergies = "";
  const validate = e => {
    validationErrors.name = !e.target.value;
  };

  $: {
    errors[key] = validationErrors.name;
  }
</script>

<style>
  .container {
    padding: 20px;
    border: 1px solid #ebb77f;
    border-radius: 10px;
    max-width: 400px;
  }
</style>

<div class="container">
  <label>
    <input
      name="{key}.fullName"
      type="text"
      bind:value
      placeholder={$_('rsvp.form.guest')}
      on:input={validate} />
    <span class="error">
      {validationErrors.name ? ` (${$_('form.required')})` : ''}
    </span>
  </label>

  <div class="bus">
    <label>
      <input name="{key}.bus" type="checkbox" bind:checked={bus} value="Yes"/>
      {$_('rsvp.form.guest.bus')}
    </label>
  </div>

  <div class="menu">
    <p>{$_('rsvp.form.guest.menu')}:</p>
    <label>
      <input
        type="radio"
        name="{key}.menu"
        bind:group={menu}
        value="With Alcohol"
        checked="true" />
      {$_('rsvp.form.guest.menu.alcohol')}
    </label>

    <label>
      <input
        type="radio"
        name="{key}.menu"
        bind:group={menu}
        value="Without Alcohol" />
      {$_('rsvp.form.guest.menu.alcohol.no')}
    </label>

    <label>
      <input type="radio" name="{key}.menu" bind:group={menu} value="Kid's Menu" />
      {$_('rsvp.form.guest.menu.kid')}
    </label>
  </div>

  <div>
    <label>
      <p>{$_('rsvp.form.guest.menu.dietary')}</p>
      <input
        name="{key}.allergies"
        type="text"
        bind:value={allergies}
        placeholder={$_('rsvp.form.guest.menu.dietary.placeholder')} />
    </label>
  </div>

</div>
