<script>
  import { _ } from "../services/i18n";
  import RSVPInfo from "./RSVPInfo.svelte";
  import Form from "../forms/rsvp.html";
  import { COUNT_BY_EMAIL } from "../rsvp-hashes.js";
  export let success = false;
  export let location;
  let visible = false;

  let login = "";
  let promise = "";

  async function verify() {
    let hashed = await getHash(login.toLowerCase());
    let matches = COUNT_BY_EMAIL.filter(rsvp => rsvp.hash == hashed);

    if (matches.length == 1) {
      let person = matches[0];
      visible = true;
      return person.count;
    } else if (matches.length > 1) {
      throw new Error("This shouldn't happen!");
    } else {
      throw new Error($_('rsvp.wrong'));
    }
  }

  function handleClick(e) {
    e.preventDefault();
    promise = verify();
  }

  async function getHash(message) {
    const msgUint8 = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest("SHA-512", msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map(b => b.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  }
</script>

<style>
  .krans {
    height: 100px;
    min-height: unset;
    display: inline-block;
    width: unset;
  }
  .date {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 64px 0;
  }

  p {
    max-width: 800px;
  }

  .rsvp {
    text-align: center;
    padding-bottom: 64px;
  }
</style>

<div>
  <picture>
    <img
      src="/images/fall/Sociala-medier-10.jpg"
      alt="Adam and Lisa smiling at you!" />
  </picture>
</div>

<h1 class="date">
  <img class="krans" src="/krans-left.svg" alt="Left krans" />
  {$_('date')}
  <img class="krans" src="/krans-right.svg" alt="Right krans" />
</h1>

{#if success}
  <div class="login">
    <h2>{$_('rsvp.success.title')}</h2>
    <p>{$_('rsvp.success.message')}</p>
  </div>
{:else}
  {#if !visible}
    <form on:submit={handleClick} class="login">
      <h2>{$_('rsvp.title')}</h2>
      <p>{$_('rsvp.description')}</p>
      <label>
        <input bind:value={login} placeholder={$_('rsvp.email')} />
      </label>
      <button type="submit">{$_('rsvp.submit')}</button>
    </form>
  {/if}

  {#await promise}
    <div class="rsvp">
      <span>...{$_('rsvp.loading')}</span>
    </div>
  {:then maxAttending}
    {#if (maxAttending != '') & visible}
      <RSVPInfo />

      <div class="rsvp">
        <div>
          <Form
            {maxAttending}
            {login}
            action={`${location.pathname}/success`} />
        </div>
      </div>
      <div>
        <picture>
          <img
            src="/images/fall/Sociala-medier-33.jpg"
            alt="Adam and Lisa smiling at you!" />
        </picture>
      </div>
    {/if}
  {:catch error}
    <div class="rsvp">
      <span class="error">{error.message}</span>
    </div>
  {/await}
{/if}
