<script>
  import Form from "../forms/change-date.html";
  import { _ } from "../services/i18n";
  export let success;
  export let location;
  let zoomed = false;

  const toggleZoom = () => {
    zoomed = !zoomed;
  };
</script>

<style type="text/scss">
  .page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    > div {
      position: relative;
      max-width: 700px;
    }
  }
  .cardConstraints {
    min-width: 600px;
    min-height: 472px;
  }
  .cardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(1px 5px 40px #fcc4bf69);
  }
  .card {
    width: 80%;
  }
  .right {
    position: absolute;
    right: 0;
    bottom: 10px;
  }
  .stamp {
    position: absolute;
    right: 0;
    &.circle {
      width: 35px;
      margin-top: 85px;
      margin-right: 100px;
      display: none;
    }
    &.rect {
      margin-top: 90px;
      margin-right: 5px;
      width: 70px;
    }
    &.waves {
      width: 70px;
      margin-right: 35px;
      margin-top: 110px;
    }
  }
  @media (min-width: 500px) {
    .card.content {
      cursor: pointer;
      &.zoomed {
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
      }
    }
  }
  @media (min-width: 800px) {
    .stamp {
      &.circle {
        width: 60px;
        margin-top: 60px;
        margin-right: 160px;
        display: block;
      }
      &.rect {
        width: 120px;
        margin-top: 40px;
        margin-right: 20px;
        z-index: -1;
      }
      &.waves {
        width: 110px;
        margin-right: 70px;
        margin-top: 90px;
      }
    }
  }
</style>

{#if success}
  <h1>{$_('form.changedate.title')}</h1>
{:else}
  <h1>{$_('mail.title')}</h1>
{/if}
<div class="page">
  <div class="cardConstraints">
    <div class="cardContainer">
      <picture class="card">
        <source srcset="/images/open_envelope.webp" type="image/webp" />
        <img
          class="card"
          src="https://drive.google.com/thumbnail?id=17OlVuqjJzB-O1vaiAbJ6n0TuZHZr0SQx&sz=w448-h545"
          alt="Open envelope with dark green liner" />
      </picture>
      <picture class="card right">
        <source srcset="/images/card_s.webp" type="image/webp" />
        <img
          class={`card content ${zoomed ? 'zoomed' : ''}`}
          src="https://drive.google.com/thumbnail?id=1qufO9QDG-nfc6H3H_JQW9HRywl2a-lTO&sz=w800-h800"
          alt="Save the date card with new wedding date"
          on:click={toggleZoom} />
      </picture>
    </div>
  </div>
  <div>
    <h2>{$_('mail.changedate.newdate.title')}</h2>
    <p>{$_('mail.changedate.newdate.bread')}</p>
    <h2>{$_('mail.changedate.reminder.title')}</h2>
    <p>{$_('mail.changedate.reminder.bread')}</p>
    {#if success}
      <h2>{$_('form.changedate.success.title')}</h2>
      <p>{$_('form.changedate.success.bread')}</p>
    {:else}
      <div>
        <img class="stamp circle" src="/images/circle_stamp.png" alt="stamp" />
        <img class="stamp rect" src="/images/rect_stamp.png" alt="stamp" />
        <img class="stamp waves" src="/images/wavy.png" alt="stamp" />
        <Form action={`${location.pathname}/success`} />
      </div>
    {/if}
  </div>
</div>
