import { dictionary, locale, _ } from "svelte-i18n";
import { derived } from "svelte/store";

const MESSAGE_FILE_URL_TEMPLATE = "/lang/{locale}.json";
const LOCALE_KEY = "adamlisawedding-locale";

const locales = ["en", "sv"];
const navigatorLocale = window.navigator.languages.find(language =>
  locales.includes(language)
);
let language =
  window.localStorage.getItem(LOCALE_KEY) || navigatorLocale || locales[0];

function setupI18n({ withLocale: _locale } = { withLocale: language }) {
  const messsagesFileUrl = MESSAGE_FILE_URL_TEMPLATE.replace(
    "{locale}",
    _locale
  );
  return fetch(messsagesFileUrl)
    .then(response => response.json())
    .then(messages => {
      dictionary.set({ [_locale]: messages });

      locale.set(_locale);
    });
}

function setLanguage(language) {
  if (!locales.includes(language)) return;
  window.localStorage.setItem(LOCALE_KEY, language);
  setupI18n({ withLocale: language });
}

function toggleLanguage() {
  language =
    locales[
      (locales.findIndex($locale => $locale === language) + 1) % locales.length
    ];
  setLanguage(language);
}

const isLocaleLoaded = derived(locale, $locale => typeof $locale === "string");

export { _, setupI18n, isLocaleLoaded, locale, setLanguage, toggleLanguage };
