<script>
  import Missing from "./404.svelte";
  import ChangeTheDate from "./MailChangeDate.svelte";
  export let location;
  export let secret;
  export let success = false;
  const CHANGE_THE_DATE =
    "h2sQ4zOZqiEJcTEPMsA65qhoxi7WWRaCrlGh6rx8Es46TEXRsobX3MJy2ucu6";
</script>

{#if secret !== CHANGE_THE_DATE}
  <Missing />
{:else}
  <ChangeTheDate {success} {location} />
{/if}
