<script>
  import { _ } from "../services/i18n";
</script>

<style>
  img {
    display: block;
    width: 100%;
  }
  p {
    max-width: 800px;
    padding: 0 32px;
  }
  h2 {
    align-self: center;
    padding: 0 32px;
  }
  .group {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 64px 0;
  }
  .infobox {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .slim {
    max-width: 400px;
  }
  @media (min-width: 800px) {
    .group {
      flex-direction: row;
    }
    .split-top {
      max-height: 20vw;
      overflow: hidden;
    }
    .split-bottom {
      overflow: hidden;
    }
    .split-bottom img {
      margin-top: -25vw;
    }
  }
</style>

<picture>
  <source
    srcset="/images/fall/Sociala-medier-15.jpg?nf_resize=fit&w=1500"
    media="(min-width: 800px)" />
  <img
    src="/images/fall/Sociala-medier-16.jpg?nf_resize=fit&w=1000"
    alt="Adam and Lisa smiling at you!" />
</picture>
<div class="group">
  <div class="infobox">
    <h2>{$_('other.guidebook.title')}</h2>
    <p>{$_('other.guidebook.description')}</p>
    <p>
      {$_('other.guidebook.link.prefix')}
      <a href="https://www.airbnb.com/s/guidebooks?refinement_paths[]=/guidebooks/2896973" target="_blank" rel="noopener">
        {$_('other.guidebook.link.text')}
      </a>
    </p>
    <h2>{$_('other.traditions.title')}</h2>
    <p>{$_('other.traditions.description')}</p>
    <p>
      {$_('other.traditions.link.prefix')}
      <a href="https://www.thenewbieguide.se/weddings-sweden-expect/">
        {$_('other.traditions.link.text')}
      </a>
    </p>
  </div>
</div>
<div class="split-top">
  <picture>
    <img
      src="/images/fall/Sociala-medier-35.jpg?nf_resize=fit&w=1500"
      alt="Adam and Lisa smiling at you!" />
  </picture>
</div>
<div class="group">
  <div class="infobox slim">
    <h2>{$_('other.dress.title')}</h2>
    <p>{$_('other.dress.description')}</p>
    <p>{$_('other.dress.guidelines')}</p>
  </div>
  <div class="infobox slim">
    <h2>{$_('other.presents.title')}</h2>
    <p>{$_('other.presents.nogifts')}</p>
    <p>{$_('other.presents.description')}</p>
  </div>
</div>
<div class="split-bottom">
  <picture>
    <source
      srcset="/images/fall/Sociala-medier-35.jpg?nf_resize=fit&w=1500"
      media="(min-width: 800px)" />
    <img
      src="/images/fall/Sociala-medier-36.jpg?nf_resize=fit&w=1500"
      alt="Adam and Lisa smiling at you!" />
  </picture>
</div>
<div class="group">
  <div class="infobox">
    <h2>{$_('other.transport.title')}</h2>
    <p>{$_('other.transport.description')}</p>
    <p>{$_('other.transport.rsvp')}</p>
    <h2>{$_('other.photography.title')}</h2>
    <p>{$_('other.photography.description')}</p>
    <p>{$_('other.photography.share')}</p>
  </div>
</div>
<picture>
  <img
    src="/images/fall/Sociala-medier-42.jpg?nf_resize=fit&w=1500"
    alt="Adam and Lisa smiling at you!" />
</picture>
