<script>
  import { _ } from "../services/i18n";
</script>

<style>
  footer {
    display: flex;
    justify-content: center;
    padding: 8px;
  }
  .contact {
    color: #25371a;
    font-family: "Euphoria Script", serif;
    text-transform: lowercase;
    letter-spacing: 1px;
    font-weight: 200;
    font-size: 1.3em;
  }
  .float {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 0.9em;
    font-weight: 200;
    color: #25371a;
    opacity: 0.3;
    padding: 12px;
  }
  @media (max-width: 600px) {
    .contact {
      padding-top: 47px;
    }
    .float {
      bottom: unset;
    }
  }
</style>

<footer>
  <span class="contact">hello@adamlisa.wedding</span>
  <span class="float">{$_('footer.photos')} Fotograf Elin Selin</span>
</footer>
