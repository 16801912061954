<script>
  import { _ } from "../services/i18n";
  import { fade } from "svelte/transition";
</script>

<style>
  p {
    max-width: 800px;
  }
  .group {
    padding: 64px 0;
  }
  .group-title {
    padding-top: 64px;
  }
  .infobox {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .center-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    max-width: 400px;
  }
  .inner {
    padding: 8px 16px;
  }
</style>

<div class="group" transition:fade>
  <div class="infobox">
    <h2>{$_('rsvp.info.friday.title')}</h2>
    <p>{$_('rsvp.info.friday.description')}</p>
    <h2 class="group-title">{$_('rsvp.info.saturday.title')}</h2>
    <p>{$_('rsvp.info.saturday.description')}</p>
    <p>{$_('rsvp.info.saturday.toastmasters')} 
      <a href="mailto:toastmasters@adamlisa.wedding" target="_blank">toastmasters@adamlisa.wedding</a>.
    </p>
    <h2 class="group-title">{$_('rsvp.info.accomodation.title')}</h2>
    <p>{$_('rsvp.info.accomodation.description')}</p>
    <span>{$_('rsvp.info.accomodation.prices')}:</span>
    <ul>
      <li>{$_('rsvp.info.accomodation.prices.single')}</li>
      <li>{$_('rsvp.info.accomodation.prices.twin')}</li>
      <li>{$_('rsvp.info.accomodation.prices.double')}</li>
    </ul>
    <p>
      {$_('rsvp.info.accomodation.breakfast')}
      <a href="https://lejondalsslott.se/">
        {$_('rsvp.info.accomodation.link')}
      </a>
      .
    </p>
    <h2 class="group-title">{$_('rsvp.info.transportation.title')}</h2>
    <p>{$_('rsvp.info.transportation.description')}</p>
    <p>{$_('rsvp.info.transportation.adresses')}:</p>
    <div class="center-flex">
      <div class="inner">
        <a href="https://www.svenskakyrkan.se/habopastorat/overgrans-kyrka">
          {$_('rsvp.info.transportation.church')}
        </a>
        <br />
        <span>{$_('rsvp.info.transportation.church.street')}</span>
        <br />
        <span>{$_('rsvp.info.transportation.church.area')}</span>
        <br />
      </div>
      <div class="inner">
        <a href="https://lejondalsslott.se/en/find-us/">
          {$_('rsvp.info.transportation.castle')}
        </a>
        <br />
        <span>{$_('rsvp.info.transportation.castle.street')}</span>
        <br />
        <span>{$_('rsvp.info.transportation.castle.area')}</span>
        <br />
      </div>
    </div>
  </div>
</div>
