<script>
  import { _ } from "../services/i18n";
  import Sigil from "../components/SigilClone.svelte";
</script>

<style>
  div {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  img {
    display: block;
    width: 100%;
    min-height: 150vw;
  }
  h2,
  p {
    padding: 0 32px;
  }
  .card .background {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 0 64px 0;
  }
  .sigil {
    position: absolute;
    left: 2%;
    bottom: -4%;
    width: 25%;
    max-width: 100px;
    z-index: 1;
  }
  .desktop {
    display: none;
  }
  .krans {
    height: 100px;
    min-height: unset;
    display: inline-block;
    width: unset;
  }
  .date {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 800px) {
    img {
      min-height: 66.6vw;
    }
  }
  @media (min-width: 1200px) {
    div {
      flex-direction: row;
    }
    .card {
      position: absolute;
      right: 7%;
      bottom: 2%;
      width: 33%;
      min-height: 60%;
      transform: rotate(4deg);
      background: #f5f4f0;
    }
    .card .background {
      padding: 64px 0;
    }
    .sigil {
      left: -15%;
    }
    .desktop {
      display: block;
    }
  }
</style>

<picture>
  <source
    srcset="/images/fall/Sociala-medier-37.jpg?nf_resize=fit&w=1500"
    media="(min-width: 800px)" />
  <img
    class="start"
    src="/images/fall/Sociala-medier-30.jpg?nf_resize=fit&w=1000"
    alt="Adam and Lisa smiling at you!" />
</picture>
<h1 class="date">
  <img class="krans" src="/krans-left.svg" alt="Left krans" />
  {$_('date')}
  <img class="krans" src="/krans-right.svg" alt="Right krans" />
</h1>
<div>
  <picture>
    <img
      class="desktop"
      src="/images/fall/Sociala-medier-44.jpg?nf_resize=fit&w=1000"
      alt="Adam and Lisa smiling at you!" />
  </picture>
  <div class="card">
    <div class="background">
      <h2>{$_('homepage.title')}</h2>
      <p>{$_('homepage.welcome')}</p>
      <p>{$_('homepage.info')}</p>
      <h2>Aah!</h2>
      <p>{$_('homepage.reminder')}</p>
      <p>{$_('homepage.thanks')}</p>
      <div class="sigil">
        <Sigil />
      </div>
    </div>
  </div>
  <picture>
    <img
      src="/images/fall/Sociala-medier-1.jpg?nf_resize=fit&w=1000"
      alt="Adam and Lisa smiling at you!" />
  </picture>
</div>
