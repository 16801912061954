<script>
  import { _ } from "../services/i18n";
</script>

<style>
  div {
    display: flex;
  }
  p {
    max-width: 400px;
  }
  .container {
    flex-direction: column-reverse;
  }
  .content {
    flex-direction: column;
    flex: 1;
  }
  .text {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 64px 0;
  }

  .desktop {
    display: none;
  }
  @media (min-width: 1200px) {
    .container {
      flex-direction: row;
    }
    .desktop {
      display: block;
      width: 50vw;
    }
  }
</style>

<picture>
  <img
    class="start"
    src="/images/fall/Sociala-medier-17.jpg?nf_resize=fit&w=1500"
    alt="Adam and Lisa smiling at you!" />
</picture>
<div class="container">
  <div class="content">
    <picture>
      <img
        class="desktop"
        src="/images/fall/Sociala-medier-11.jpg?nf_resize=fit&w=1000"
        alt="Adam and Lisa smiling at you!" />
    </picture>
    <div class="text">
      <h2>{$_('schedule.stay.title')} - {$_('schedule.stay.location')}</h2>
      <p>{$_('schedule.stay.description')}</p>
    </div>
  </div>
  <div class="content">
    <div class="text">
      <h2>
        {$_('schedule.ceremony.title')} - {$_('schedule.ceremony.location')}
      </h2>
      <p>{$_('schedule.ceremony.description')}</p>
      <h2>
        {$_('schedule.dinnerparty.title')} - {$_('schedule.dinnerparty.location')}
      </h2>
      <p>{$_('schedule.dinnerparty.dinner')}</p>
      <p>{$_('schedule.dinnerparty.coffee')}</p>
      <p>{$_('schedule.dinnerparty.party')}</p>
    </div>
    <picture>
      <img
        src="/images/fall/Sociala-medier-24.jpg?nf_resize=fit&w=1500"
        alt="Adam and Lisa smiling at you!" />
    </picture>
  </div>
</div>
